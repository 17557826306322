import React from "react";
import Modal from "react-modal";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";

import "./UploadModal.scss";
import { Meet } from "types";
import get from "lodash/get";
import PastDueInvoicesModal from "components/pastDueInvoicesModal/PastDueInvoicesModal";
import PouchInput from "components/PouchInput";

type UploadModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onRequestClose: () => void;
  meet: Meet;
  meetId: string;
  onUpload: ({
    password,
    confirmationToken,
    emailAddress,
  }: {
    password: string;
    confirmationToken: string;
    emailAddress: string;
  }) => Promise<void>;
};
type UploadModalState = {
  password: string;
  passwordConfirmation: string;
  error: string;
  step:
    | "confirmBillingEmail"
    | "paymentMethodOnFile"
    | "confirmTokenBranch"
    | "confirmNewToken"
    | "confirmOldToken"
    | "setPassword";
  confirmationToken: string;
  loading: boolean;
};

const initialState: UploadModalState = {
  password: "",
  passwordConfirmation: "",
  error: "",
  step: "confirmBillingEmail",
  confirmationToken: "",
  loading: false,
};

const UploadModal = ({
  meet,
  meetId,
  onCancel,
  onRequestClose,
  isOpen,
  onUpload,
}: UploadModalProps) => {
  const [pastDueInvoicesModalOpen, setPastDueInvoicesModalOpen] =
    React.useState<false | { name: string; invoiceUrl: string }[]>(false);
  const [state, setState] = React.useState<UploadModalState>(initialState);

  const onChange = (
    value: string,
    name: "password" | "passwordConfirmation" | "confirmationToken"
  ) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onError = (errorMessage: string) => {
    onCancel();
    onCloseAndResetForm();
    alert(errorMessage);
  };

  const onCancelInternal = () => {
    onCancel();
    onCloseAndResetForm();
  };

  const onCloseAndResetForm = () => {
    setState(initialState);
    onRequestClose();
  };

  const onClickSendEmailToken = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    // send token email when doing initial meet upload
    const emailAddress = get(meet, "contactEmail");
    try {
      const response = await fetchWrapper(
        `/api/meets/${meetId}/email_validation`,
        "POST",
        {
          email_address: emailAddress,
        }
      );
      if (response.ok) {
        setState((prev) => ({
          ...prev,
          step: "confirmNewToken",
          loading: false,
        }));
      } else {
        if (response.invoices) {
          setPastDueInvoicesModalOpen(response.invoices);
          setState((prev) => ({ ...prev, loading: false }));
        } else {
          onError(response.error);
        }
      }
    } catch (error: any) {
      errorReporter({ message: "Email Token Failure", error });
      onError("Something went wrong sending email token");
    }
  };

  const onConfirmBillingEmail = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "paymentMethodOnFile" }));
  };

  const onConfirmPaymentMethodOnFile = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    const emailAddress = meet.contactEmail ?? "";
    try {
      const response = await fetchWrapper(
        `/api/stripe_payment_method_check?emailAddress=${encodeURIComponent(
          emailAddress
        )}`,
        "GET"
      );
      if (response.ok) {
        setState((prev) => ({
          ...prev,
          step: "confirmTokenBranch",
          loading: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          loading: false,
          error:
            "No default payment method found. Ensure you have a default payment method listed in the LiftingCast Stripe Customer Portal.",
        }));
      }
    } catch (error: any) {
      errorReporter({ message: "stripe_payment_method_check Failure", error });
      onError("Something went wrong checking for payment method on file.");
    }
  };

  const onClickAlreadyHaveEmailToken = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "confirmOldToken" }));
  };

  const onClickEnterToken = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "setPassword" }));
  };

  const onClickContinue = async (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!state.password) {
      setState((prev) => ({ ...prev, error: "Must enter a password." }));
      return;
    }

    if (state.password === state.passwordConfirmation) {
      setState((prev) => ({ ...prev, loading: true }));
      await onUpload({
        password: state.password,
        confirmationToken: state.confirmationToken,
        emailAddress: meet.contactEmail ?? "",
      });
      onCloseAndResetForm();
    } else {
      setState((prev) => ({ ...prev, error: "Passwords must match." }));
    }
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onCancelInternal}
        shouldCloseOnOverlayClick={false}
        className={{
          base: "upload-modal",
          afterOpen: "upload-modal-after-open",
          beforeClose: "upload-modal-before-close",
        }}
        overlayClassName={{
          base: "upload-modal-overlay",
          afterOpen: "upload-modal-overlay-after-open",
          beforeClose: "upload-modal-overlay-before-close",
        }}
        contentLabel="upload modal"
      >
        <div className="content">
          {state.loading && <div className="login-loading-overlay"></div>}
          {state.loading && (
            <div className="login-loading-text">Loading...</div>
          )}

          <div>
            {state.step === "confirmBillingEmail" && (
              <div>
                <div>
                  Using LiftingCast in offline mode is free. Using the online
                  cloud services cost $1.25 per lifter per event. To be paid
                  after the meet is finished. By continuing you agree to allow
                  LiftingCast to charge your payment method on file based on
                  your usage for this event. See our{" "}
                  <a href="/terms-of-service.html">terms of service</a> for more
                  details.
                  <hr />
                  <br />
                </div>
                <div className="title">
                  Please confirm your billing contact email:
                </div>
                <PouchInput
                  type="text"
                  meetId={meet._id}
                  documentId={meet._id}
                  name="contactEmail"
                  value={get(meet, "contactEmail")}
                  beforeSave={(value) =>
                    typeof value === "string" ? value.toLowerCase() : value
                  }
                />
                <br />
                <br />
                <form>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <div>
                      <input
                        disabled={state.loading}
                        style={{ minWidth: 200 }}
                        type="submit"
                        onClick={onConfirmBillingEmail}
                        value="Next"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {state.step === "paymentMethodOnFile" && (
              <div>
                <div className="title">
                  Before continuing you must have a payment method on file with
                  our payment provider Stripe. Your event will NOT automatically
                  be charged to this payment method. You will be given the
                  opportunity to pay your invoice with a different payment
                  method after your event as long as your do so before the
                  invoice due date.
                </div>
                {meet.contactEmail ? (
                  <a
                    target="_blank"
                    href={`/api/stripe_portal?emailAddress=${encodeURIComponent(
                      meet.contactEmail
                    )}`}
                    rel="noreferrer"
                  >
                    Click here to enter the LiftingCast Stripe Customer Portal
                  </a>
                ) : (
                  "Missing Email"
                )}
                <br />
                <br />
                <div>
                  Stripe will send you a login link via email to the billing
                  contact email you have provided. From the LiftingCast Stripe
                  Customer Portal you can manage your payment methods and
                  invoices. Ensure you have a valid default payment method
                  listed before continuing.
                </div>
                <div className="error">
                  {state.error && <div>{state.error}</div>}
                </div>
                <br />
                <form>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <div>
                      <input
                        disabled={state.loading}
                        style={{ minWidth: 200 }}
                        type="submit"
                        onClick={onConfirmPaymentMethodOnFile}
                        value="I have a default payment method"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {state.step === "confirmTokenBranch" && (
              <div>
                <div className="title">
                  You must validate your billing email address to continue.
                </div>
                <div className="title">
                  Email validation token will be sent to:
                </div>
                <div className="title">{get(meet, "contactEmail")}</div>
                <div>
                  If you have previously validated this email address for
                  another event you can use the previous token.
                </div>
                <br />
                <br />
                <form>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <div>
                      <input
                        disabled={state.loading}
                        style={{ marginBottom: 20, minWidth: 200 }}
                        type="button"
                        onClick={onClickAlreadyHaveEmailToken}
                        value="I Already Have A Token"
                      />
                      <input
                        disabled={state.loading}
                        style={{ minWidth: 200 }}
                        type="submit"
                        onClick={onClickSendEmailToken}
                        value="Send New Confirmation Token"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {(state.step === "confirmNewToken" ||
              state.step === "confirmOldToken") && (
              <div>
                {state.step === "confirmNewToken" && (
                  <div className="title">
                    Email validation token has been sent to{" "}
                    {get(meet, "contactEmail")}. If you did not receive the
                    email check your spam folder. Some email providers like
                    Yahoo and AOL can take several hours to deliver the emails.
                  </div>
                )}
                {state.step === "confirmOldToken" && (
                  <div className="title">
                    Enter email validation token previously sent to{" "}
                    {get(meet, "contactEmail")}.
                  </div>
                )}
                <br />
                <div>
                  <form>
                    <input
                      autoFocus
                      type="text"
                      placeholder="confirmation token"
                      name="confirmation-token"
                      onChange={(e) =>
                        onChange(e.target.value, "confirmationToken")
                      }
                    />
                    <br />
                    <br />
                    <div className="button-row">
                      <input
                        disabled={state.loading}
                        className="password-cancel-button"
                        type="button"
                        onClick={onCancelInternal}
                        value="Cancel"
                      />
                      <input
                        disabled={state.loading}
                        type="submit"
                        onClick={onClickEnterToken}
                        value="Next"
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
            {state.step === "setPassword" && (
              <div>
                <div className="title">Set Shareable Password</div>
                <div className="title">
                  This password can be used to log into this meet from other
                  computers. The password you set is only for this event and
                  should be unique to this event.
                </div>
                <form>
                  <input
                    type="text"
                    name="username"
                    defaultValue={meetId}
                    style={{ display: "none" }}
                  />
                  <input
                    autoFocus
                    type="password"
                    placeholder="password"
                    name="current-password"
                    onChange={(e) => onChange(e.target.value, "password")}
                  />
                  <input
                    type="password"
                    className="confirm-password"
                    placeholder="confirm password"
                    name="confirm-password"
                    onChange={(e) =>
                      onChange(e.target.value, "passwordConfirmation")
                    }
                  />
                  <div className="error">
                    {state.error && <div>{state.error}</div>}
                  </div>
                  <br />
                  <div>
                    You will be invoiced for this event after your event takes
                    place. Invoices are due 7 days after the event. See our{" "}
                    <a href="/terms-of-service.html">terms of service</a> for
                    more details.
                  </div>
                  <br />
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <input
                      disabled={state.loading}
                      type="submit"
                      onClick={onClickContinue}
                      value={"Agree To Pay"}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <PastDueInvoicesModal
        isOpen={!!pastDueInvoicesModalOpen}
        onRequestClose={() => setPastDueInvoicesModalOpen(false)}
        invoices={pastDueInvoicesModalOpen || []}
      />
    </>
  );
};

export default UploadModal;
