import React from "react";
import { Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import Navigation from "app/meets/Navigation";
import ConnectedIcon from "icons/ConnectedIcon";
import { useLifter } from "util/useLifter";
import { useMeet } from "util/useMeet";
import { usePlatform } from "util/usePlatform";
import { useStatus } from "util/useStatus";

const LifterTitle = () => {
  const lifter = useLifter();
  const meet = useMeet();
  const lifterName = lifter.name ?? "";
  const meetName = meet.name ?? "";

  if (!meetName) {
    return null;
  }

  const pageTitle = `${lifterName} - ${meetName}`;
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {pageTitle}
    </div>
  );
};

const PlatformTitle = () => {
  const platform = usePlatform();
  const meet = useMeet();
  const currentPlatformName = platform.name ?? "";
  const meetName = meet.name ?? "";
  const pageTitle = `Platform ${currentPlatformName} - ${meetName}`;

  if (!meetName) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {pageTitle}
    </div>
  );
};

const MeetTitle = () => {
  const meet = useMeet();
  const meetName = meet.name ?? "";

  if (!meetName) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>{meetName}</title>
      </Helmet>
      {meetName}
    </div>
  );
};

const Header = ({ openPasswordModal }: { openPasswordModal: any }) => {
  const { isSyncing, isSyncActive, isLoading, isLocal, isOnline } = useStatus();

  return (
    <div className="header">
      <Navigation openPasswordModal={openPasswordModal} />

      <div className="meet-name">
        <Switch>
          <Route
            path="/meets/:meetId/lifter/:lifterId"
            component={LifterTitle}
          />
          <Route
            path="/meets/:meetId/platforms/:platformId"
            component={PlatformTitle}
          />
          <Route path="/meets/:meetId" component={MeetTitle} />
        </Switch>
        {!isLoading && isLocal && isOnline && !isSyncing && (
          <span style={{ color: "red", marginLeft: 5 }}>
            - NOT CONNECTED - NOT SYNCING DATA
          </span>
        )}
      </div>
      {!isLoading && isLocal && isOnline && (
        <div className="sync-status">
          <ConnectedIcon
            isSyncing={isSyncing}
            className={classNames({
              "sync-disabled": !isSyncing,
              "sync-active": isSyncActive,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
